$azul: #0047a9;

html {
  overflow-x: hidden;

  body {
    ul, p, li, h1, h2, h3, h4, label {
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }
}

.center-login {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-image: url("../img/back.jpg");
  background-size: cover;
  background-position: center center;
}

.modal-body {
  .res {
    height: 45vh;
    overflow-y: auto;
    overflow-x: hidden;

    table {
      img {
        max-height: 120px;
      }
    }
  }
}

.message-pop {
  display: none;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 20px;
  background: blue;
  z-index: 1200;

  p {
    text-align: center;
    color: white;
  }
}

.title-section {
  color: $azul;
  text-transform: uppercase;
  margin: 10px 0;
  font-weight: 700;
}


.btn-primary {
  background-color: $azul;
  border-color: $azul;
}

.sidebar {
  background: $azul;
  height: 100vh;
  position: fixed;
  width: 212px;

  .user-data {
    p {
      font-size: 10px;
      color: white;
    }
  }

  ul {
    li {
      display: block;
      width: 100%;

      a {
        padding: 5px 10px;
        display: block;
        color: white;
        width: 100%;

        &:hover {
          text-decoration: none;
          background: white;
          color: $azul;
        }
      }
    }
  }
}